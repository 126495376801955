import styles from './BusinessCard.module.css';
import QRCode from 'react-qr-code';

export default function BusinessCard({ data, id, qrRef }) {
  return (
    <div className={styles.root} onClick={() => console.log(qrRef)}>
      <div className={styles.TextInfo}>
        <h1>{data.name ? data.name : 'Unknown'}</h1>
        {data.designation ? (
          <h3 title={data.designation}>{data.designation}</h3>
        ) : null}
        {data.companyName ? (
          <h3 title={data.companyName}>
            {data.companyName.length > 20
              ? data.companyName.substring(0, 20) + '...'
              : data.companyName}
          </h3>
        ) : null}
        {/* <br /> */}
        {data.email ? (
          <h3 title={data.email}>
            {data.email.length > 20
              ? data.email.substring(0, 20) + '...'
              : data.email}
          </h3>
        ) : null}
        <h3>{data.phone}</h3>
      </div>
      <div className="QRCode">
        <QRCode
          ref={qrRef}
          id="qr"
          size={256}
          style={{
            height: 'auto',
            maxWidth: '100%',
            width: '100%',
          }}
          value={`https://www.app.bondu.in/user/${id}`}
          viewBox={`0 0 256 256`}
        />
      </div>
    </div>
  );
}
