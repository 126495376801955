import { useState } from 'react';
import styles from './FTUEPage.module.css';
import InputField from './InputField';
export default function Page2({ onSubmit, initialData }) {
  const [companyName, setCompanyName] = useState(
    initialData['companyName'] ?? ''
  );
  const [designation, setDesignation] = useState(
    initialData['designation'] ?? ''
  );

  return (
    <div className={styles.Page1}>
      <h1>Where you work</h1>
      <h4>Enter details about your work</h4>
      <InputField
        initialValue={initialData['companyName']}
        title={'Company Name'}
        onChange={setCompanyName}
      />
      <InputField
        initialValue={initialData['designation']}
        title={'Designation'}
        onChange={setDesignation}
      />
      <button
        className={styles.NextButton}
        onClick={() => {
          onSubmit(companyName, designation);
        }}
      >
        Next
      </button>
    </div>
  );
}
