import React from 'react';
import './PrivacyPolicy.css';

const ShippingDeliveryPage = () => {
  return (
    <div className="privacy-policy">
      <h1>BondU Shipping and Delivery Policy</h1>
      <p>Effective Date: 7/9/2024</p>
      <p>
        At BondU, we offer both digital and physical products. This policy
        outlines how we handle the shipping and delivery of our **smart business
        cards** as well as the delivery of our digital services. We aim to
        provide a seamless experience for our customers, ensuring timely and
        secure delivery of all orders.
      </p>

      <section>
        <h2>1. Delivery of Physical Products</h2>
        <ul>
          <li>
            <h3>1.1. Order Processing Time</h3>
            <ul>
              <li>
                All orders for physical **smart business cards** are processed
                within **1-2 business days** (excluding weekends and public
                holidays) after receiving your order confirmation
              </li>
              <li>
                You will receive a notification via email once your order has
                been dispatched.
              </li>
            </ul>
          </li>
          <li>
            <h3>1.2. Shipping Charges</h3>
            <ul>
              <li>
                Standard Shipping: We offer free standard shipping for all
                orders across India.
              </li>
              <li>
                Express Shipping: For expedited shipping, additional charges may
                apply based on the delivery location. Shipping options and costs
                will be displayed at checkout
              </li>
            </ul>
          </li>
          <li>
            <h3>1.3. Delivery Timeframe</h3>
            <ul>
              <li>
                Standard Delivery: Typically, delivery takes between **5-7
                business days** after the order has been processed
              </li>
              <li>
                Express Delivery: For express shipping, delivery takes between
                **2-3 business days** after the order is processed, depending on
                the delivery location.
              </li>
              <li>
                Delivery times may vary based on the recipient's location,
                weather conditions, and courier service availability.
              </li>
            </ul>
          </li>
          <li>
            <h3>1.4. Shipping Partners</h3>
            <p>
              We work with reputable courier services like **Blue Dart**,
              **Delhivery**, and **India Post** to ensure your order reaches you
              safely and on time.
            </p>
          </li>
          <li>
            <h3>1.5. Delivery Locations</h3>
            <p>
              We deliver our **smart business cards** across **all regions of
              India**. Unfortunately, we do not offer international shipping for
              physical products at this time.
            </p>
          </li>
          <li>
            <h3>1.6. Tracking Your Order</h3>
            <p>
              Once your order has been dispatched, you will receive a tracking
              number via email or SMS. You can use this tracking number to
              monitor the status of your delivery in real-time through the
              courier partner’s website.
            </p>
          </li>
        </ul>
      </section>

      <section>
        <h2>2. Delivery of Digital Services</h2>
        <p>To use BondU, you must be:</p>
        <ul>
          <li>
            <h3>2.1. Instant Access to Digital Services</h3>
            <ul>
              <li>
                Upon successful subscription or purchase of any **BondU**
                digital plans, you will gain instant access to the premium
                features through your registered account within the BondU app.
              </li>
              <li>
                You will receive a confirmation email with details of your
                purchase and instructions for accessing the premium features.
              </li>
            </ul>
          </li>
          <li>
            <h3>2.2. Account Activation</h3>
            <ul>
              <li>
                Once you register or subscribe, a confirmation email will be
                sent to the email address provided during sign-up.
              </li>
              <li>
                If there is any delay in activating your premium features,
                please contact us at **bondu.dbc@gmail.com**, and we will
                resolve the issue within **24-48 hours**
              </li>
            </ul>
          </li>
          <li>
            <h3>2.3. Subscription Renewals</h3>
            <ul>
              <li>
                For auto-renewal subscriptions, your access to BondU’s premium
                digital features will continue without interruption at the end
                of each billing cycle.
              </li>
              <li>
                You will receive a notification or reminder of upcoming
                renewals, giving you the option to cancel or modify your
                subscription before the next billing period.
              </li>
            </ul>
          </li>
        </ul>
      </section>

      <section>
        <h2>3. Failed Delivery Attempts</h2>
        <ul>
          <li>
            <h3>3.1. Incorrect Address</h3>
            <ul>
              <li>
                If the address provided during checkout is incorrect or
                incomplete, the delivery may be delayed or returned to us.
                Please ensure all shipping details are accurate before placing
                your order.
              </li>
              <li>
                If your order is returned due to an incorrect address, you will
                be responsible for any additional shipping charges to resend the
                product
              </li>
            </ul>
          </li>
          <li>
            <h3>3.2. Missed Delivery</h3>
            <ul>
              <li>
                Our delivery partners will attempt to deliver your order
                multiple times. If all attempts fail and the product is returned
                to us, we will contact you to arrange for re-delivery
                (additional charges may apply).
              </li>
            </ul>
          </li>
        </ul>
      </section>

      <section>
        <h2>4. Shipping Delays</h2>
        <ul>
          <li>
            <b>Unforeseen Delays</b>: While we strive to deliver within the
            timeframes mentioned, certain circumstances such as adverse weather
            conditions, national holidays, strikes, or pandemics may cause
            unavoidable delays. In such cases, we will keep you informed and
            work with our shipping partners to ensure the order reaches you as
            soon as possible.
          </li>
          <li>
            **Courier Delays**: Once the order is dispatched, any delays by the
            courier service are outside of our control. However, we will assist
            in tracking your package and providing updates.
          </li>
        </ul>
      </section>

      <section>
        <h2>5. Cancellations and Modifications</h2>
        <li>
          <h3>5.1. Order Cancellations (Physical Products)</h3>
          <ul>
            <li>
              You may cancel your order for physical products before it is
              shipped. To cancel, contact us at **bondu.dbc@gmail.com** with
              your order details.
            </li>
            <li>
              Once an order has been shipped, cancellations will not be
              accepted.
            </li>
          </ul>
        </li>
        <li>
          <h3>5.2. Modifying Your Order</h3>
          <ul>
            <li>
              If you wish to modify your order (change address, adjust
              quantities, etc.), please contact us as soon as possible. We will
              do our best to accommodate changes if your order has not yet been
              processed.
            </li>
          </ul>
        </li>
      </section>
      <section>
        <h2>6. Missing or Damaged Items</h2>
        <ul>
          <li>
            <h3>6.1. Missing Items</h3>
            <ul>
              <li>
                If your package arrives and items are missing, please contact us
                immediately at **bondu.dbc@gmail.com** with your order number
                and details. We will investigate and resolve the issue as
                quickly as possible
              </li>
            </ul>
          </li>
          <li>
            <h3>6.2. Damaged Products</h3>
            <ul>
              <li>
                If your **smart business cards** arrive damaged, please notify
                us within **48 hours** of delivery with photos of the damaged
                product and packaging. We will arrange for a replacement at no
                extra cost.
              </li>
            </ul>
          </li>
        </ul>
      </section>

      <section>
        <h2> 7. Contact Us</h2>
        <p>
          If you have any questions or need assistance with your order, please
          feel free to reach out to us at: Email: bondu.dbc@gmail.com <br /> We
          are here to assist you with any issues related to the shipping or
          delivery of both physical and digital products
        </p>
      </section>
    </div>
  );
};

export default ShippingDeliveryPage;
