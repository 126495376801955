import { useState } from 'react';
import styles from './FTUEPage.module.css';
export default function InputField({ title, onChange, initialValue }) {
  const [value, setValue] = useState(initialValue);

  return (
    <div className={styles.InputField}>
      <h3>{title}</h3>
      <input
        type="text"
        value={value}
        name={title}
        onChange={(e) => {
          onChange(e.target.value);
          setValue(e.target.value);
        }}
      />
    </div>
  );
}
