import { useState } from 'react';
import styles from './FTUEPage.module.css';
import InputField from './InputField';
export default function Page1({ onSubmit, initialData }) {
  const [name, setName] = useState(initialData['name'] ?? '');
  const [email, setEmail] = useState(initialData['email'] ?? '');

  return (
    <div className={styles.Page1}>
      <h1>Welcome</h1>
      <h4>Enter your personal details</h4>
      <InputField
        initialValue={initialData['name']}
        title={'Name'}
        onChange={setName}
      />
      <InputField
        initialValue={initialData['email']}
        title={'Email'}
        onChange={setEmail}
      />
      <button
        className={styles.NextButton}
        onClick={() => {
          onSubmit(name, email);
        }}
      >
        Next
      </button>
    </div>
  );
}
