import { useState } from 'react';
import styles from './FTUEPage.module.css';

function getPlaceholderText(social) {
  if (social === 'Whatsapp') return 'Paste your Whatsapp Number here';
  if (social === 'Web') return 'Paste your Website link here';
  if (social === 'Maps') return 'Paste your Map location link here';
  else return `Paste your ${social} profile link here`;
}

function completeURL(url) {
  const httpsRegex = /^https:/;
  const httpRegex = /^http:/;
  if (!httpsRegex.test(url) && !httpRegex.test(url)) {
    return 'https://' + url;
  } else return url;
}

export default function AddSocialPopUp({ data, closeFunction, onSave }) {
  const [value, setValue] = useState(data.value ?? '');

  return (
    <div className={styles.AddSocialPopUp + ' ' + styles.popup}>
      <h4>Connect your socials</h4>
      <img src={data.img} alt="" />
      <h3>{data.title}</h3>
      <input
        type="text"
        onChange={(e) => setValue(e.target.value.trim())}
        value={value}
        name=""
        placeholder={getPlaceholderText(data.title)}
      />

      <div>
        <button className={styles.cancelButton} onClick={closeFunction}>
          Cancel
        </button>
        <button
          className={styles.saveButton}
          onClick={() => {
            if (data.title === 'Whatsapp' && value.length < 10) {
              alert('Invalid Phone Number: Should be a 10-digit phone number');
              return;
            }

            if (data.title === 'Whatsapp') {
              onSave(value === '' || value == null ? null : value);
            } else {
              onSave(value === '' || value == null ? null : completeURL(value));
            }

            closeFunction();
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
}
