import { useNavigate } from 'react-router-dom';
import styles from '../pages/DashboardPage/DashboardPage.module.css';

export default function Sidebar({ sidebar, setSidebar, setMenu, menu }) {
  const nav = useNavigate();

  const logOut = () => {
    localStorage.clear();
    nav('/login');
  };

  console.log(sidebar);

  return (
    <>
      <div className={styles.topNavBar}>
        <img
          onClick={() => setSidebar(!sidebar)}
          src="https://cdn-icons-png.flaticon.com/512/6015/6015685.png"
          alt=""
        />
        <h2>BondU</h2>
        <p></p>
      </div>
      <div className={styles.sidebar + ' ' + (sidebar ? null : styles.hide)}>
        <h1>BondU</h1>
        <div className={styles.navLinks}>
          <button
            className={
              styles.navButton +
              ' ' +
              (menu === 0 ? styles.selectedNavButton : '')
            }
            onClick={() => {
              if (menu !== 0) setMenu(0);
              setSidebar(false);
            }}
          >
            <img
              src="	https://cdn-icons-png.flaticon.com/512/13690/13690457.png"
              alt=""
            />
            Home
          </button>
          <button
            className={
              styles.navButton +
              ' ' +
              (menu === 1 ? styles.selectedNavButton : '')
            }
            onClick={() => {
              if (menu !== 1) setMenu(1);
              setSidebar(false);
            }}
          >
            <img
              src="https://cdn-icons-png.flaticon.com/512/3095/3095610.png"
              alt=""
            />
            Contacts
          </button>
          <button
            className={
              styles.navButton +
              ' ' +
              (menu === 2 ? styles.selectedNavButton : '')
            }
            onClick={() => {
              if (menu !== 2) setMenu(2);
              setSidebar(false);
            }}
          >
            <img
              src="https://cdn-icons-png.flaticon.com/512/10416/10416163.png"
              alt=""
            />
            Analytics
          </button>
        </div>
        <button
          onClick={logOut}
          className={
            styles.navButton +
            ' ' +
            styles.selectedNavButton +
            ' ' +
            styles.logOutButton
          }
        >
          Log out
        </button>
      </div>
    </>
  );
}
