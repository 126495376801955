import React from 'react';
import './PrivacyPolicy.css';

const TermsNCondition = () => {
  return (
    <div className="privacy-policy">
      <h1>BondU Terms and Conditions</h1>
      <p>Effective Date: 7/9/2024</p>
      <p>
        Welcome to BondU! These Terms and Conditions govern your use of our
        platform and services. By accessing or using BondU, you agree to comply
        with and be bound by these terms.
      </p>

      <section>
        <h2>1. Acceptance of Terms</h2>
        <p>
          By registering for or using BondU, you acknowledge that you have read,
          understood, and agree to these Terms and Conditions, as well as our
          Privacy Policy. If you do not agree to these terms, you should not use
          the platform.
        </p>
      </section>

      <section>
        <h2> 2. Eligibility</h2>
        <p>To use BondU, you must be:</p>
        <ul>
          <li>
            At least 14 years old, or the legal age of majority in your
            jurisdiction.
          </li>
          <li>Capable of entering into a legally binding agreement.</li>
        </ul>
        <p>
          If you do not meet these requirements, you are not permitted to use
          BondU.
        </p>
      </section>

      <section>
        <h2>3. User Accounts</h2>
        <p>
          To access BondU's full features, you will need to create a user
          account.
        </p>
        <ul>
          <li>
            <b>Account Security</b>: You are responsible for maintaining the
            confidentiality of your account credentials. You agree to notify us
            immediately of any unauthorized use of your account.
          </li>
          <li>
            <b>Accuracy of Information</b>: You agree to provide accurate,
            current, and complete information during registration and to update
            your details as needed.
          </li>
        </ul>
      </section>

      <section>
        <h2>4. Use of the Service</h2>

        <ul>
          <li>
            {' '}
            <b>Permitted Use</b>: BondU is designed for creating and sharing
            digital business cards, networking, and managing contact
            information. You agree to use the platform for its intended purposes
            only.
          </li>
          <li>
            Prohibited Conduct: You agree not to:
            <ul>
              <li>Use BondU for any illegal or unauthorized purposes.</li>
              <li>Violate any applicable laws or regulations.</li>
              <li>Distribute harmful or malicious software.</li>
              <li>
                Engage in spamming, phishing, or other unethical activities
              </li>
            </ul>
          </li>
        </ul>
      </section>

      <section>
        <h2>5. Intellectual Property</h2>
        <p>
          All intellectual property, including trademarks, logos, designs, and
          content, provided by BondU belongs to BondU or its licensors. You are
          not granted any rights to use these materials beyond what is necessary
          for using the service in accordance with these Terms.
        </p>
      </section>
      <section>
        <h2>6. User Content</h2>
        <ul>
          <li>
            <b>Ownership</b>: Any content you upload or share through BondU
            remains your property. By uploading content, you grant BondU a
            non-exclusive, royalty-free, worldwide license to use, modify, and
            display your content for the purposes of providing the service.
          </li>
          <li>
            <b>Responsibility</b>: You are solely responsible for the content
            you share. BondU is not liable for any errors or omissions in user
            content.
          </li>
        </ul>
      </section>

      <section>
        <h2>7. Payment and Subscriptions</h2>

        <ul>
          <li>Some features of BondU may require a paid subscription.</li>
          <li>
            By subscribing, you agree to pay the fees associated with the
            subscription plan you select. Payments are non-refundable unless
            stated otherwise in the <b>Cancellation and Refund Policy</b>.
          </li>
          <li>
            BondU reserves the right to modify pricing or subscription terms,
            with notice provided in advance.
          </li>
        </ul>
      </section>
      <section>
        <h2>8. Limitation of Liability</h2>
        <p>
          BondU provides its services "as is" without warranties of any kind,
          either expressed or implied. BondU will not be held liable for any:
        </p>
        <ul>
          <li>
            Losses or damages, including indirect or consequential losses.
          </li>
          <li>Databreaches, except in cases of gross negligence.</li>
        </ul>
      </section>
      <section>
        <h2>9. Termination</h2>
        <p>
          BondU reserves the right to suspend or terminate your account at any
          time if you violate these Terms and Conditions or engage in illegal
          activities on the platform.
        </p>
      </section>
      <section>
        <h2>10. Changes to Terms</h2>
        <p>
          BondU may update these Terms and Conditions periodically. When changes
          are made, we will notify you within the app, and the updated terms
          will be effective immediately.
        </p>
      </section>
      <section>
        <h2>11. Governing Law</h2>
        <p>
          These terms are governed by and construed in accordance with the laws
          of the Republic of India. Any disputes related to these terms will be
          resolved in the courts of Uttar Pradesh.
        </p>
      </section>
      <section>
        <h2>12. Contact Us</h2>
        <p>
          If you have any questions or concerns regarding these Terms and
          Conditions, please contact us at:
        </p>
        <ul>
          <li>
            <b>Email</b>: bondu.dbc@gmail.com
          </li>
        </ul>
      </section>
    </div>
  );
};

export default TermsNCondition;
