import './App.css';
import ProtectedRoute from './components/protected_route';
import DashboardPage from './pages/DashboardPage/DashboradPage';
import FTUEPage from './pages/FTUE/FTUEPage';
import LoginPage from './pages/LoginPage/LoginPage';
import CancellationRefund from './pages/PrivacyPolicyPage/CancellationRefundPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage/PrivacyPolicyPage';
import ShippingDeliveryPage from './pages/PrivacyPolicyPage/ShippingDeliveryPage';
import TermsNCondition from './pages/PrivacyPolicyPage/TermsNConditionPage';
import UserView from './pages/UserView2/UserView';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/privacy_policy" element={<PrivacyPolicyPage />} />
          <Route path="/Cancellation_Refund" element={<CancellationRefund />} />
          <Route path="/Shipping_delivery" element={<ShippingDeliveryPage />} />
          <Route path="/terms_conditions" element={<TermsNCondition />} />

          <Route path="/user/:userID" element={<UserView />} />
          <Route element={<ProtectedRoute />}>
            <Route element={<DashboardPage />} path="/dashboard/" />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route path="/ftue/" element={<FTUEPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
