import { useEffect, useReducer, useState } from 'react';
import styles from './FTUEPage.module.css';
import AddSocialPopUp from './AddSocialPopUp';
import MoreSocialsPopUp from './MoreSocialsPopUp';
import { socialMediaHandlesDict } from '../../constants';

const reducerFn = (state, action) => {
  console.log(action.type);

  var handles = [];

  if (action.type === 'REGULAR') {
    for (var key in action.value) {
      handles.push({
        title: key,
        img: 'https://cdn-icons-png.flaticon.com/512/145/145802.png',
        value: action.value[key] ?? null,
      });
    }

    return handles;
  }

  if (action.type === 'DEFAULT') {
    handles = action.value;
    return handles;
  }

  if (action.type === 'ADD_NEW') {
    handles = [...state];
    handles.push(action.value);

    return handles;
  }
};

export default function Page3({ onSubmit, initialData, addHandle }) {
  const [socials, setSocials] = useReducer(reducerFn, []);

  const defaultHandles = [
    {
      title: 'Whatsapp',
      img: 'https://cdn-icons-png.flaticon.com/512/3670/3670051.png',
      value: initialData.socialMediaHandles['Whatsapp'] ?? null,
    },
    {
      title: 'LinkedIn',
      img: 'https://cdn-icons-png.flaticon.com/512/145/145807.png',
      value: initialData.socialMediaHandles['LinkedIn'] ?? null,
    },
    {
      title: 'X',
      img: 'https://cdn-icons-png.flaticon.com/512/5969/5969020.png',
      value: initialData.socialMediaHandles['X'] ?? null,
    },
    {
      title: 'Facebook',
      img: 'https://cdn-icons-png.flaticon.com/512/145/145802.png',
      value: initialData.socialMediaHandles['Facebook'] ?? null,
    },
  ];

  const addHandles = () => {
    var count = 0;
    var initialHandles = [];
    for (var key in initialData.socialMediaHandles) {
      count++;
      initialHandles.push({
        title: key,
        value: initialData.socialMediaHandles[key],
        img: socialMediaHandlesDict[key],
      });
    }

    console.log(count);
    if (count === 0) {
      setSocials({ type: 'DEFAULT', value: defaultHandles });
    } else if (count <= 4) {
      setSocials({
        type: 'DEFAULT',
        value: initialHandles,
      });
    } else {
      setSocials({ type: 'REGULAR', value: initialData.socialMediaHandles });
    }
  };

  const addMoreHandles = (title, imgURL) => {
    const newSocial = {
      title: title,
      img: imgURL,
      value: null,
    };
    setSocials({
      type: 'ADD_NEW',
      value: newSocial,
    });
    setActiveSocial(newSocial);
    toggleMoreSocialsPopUp(false);
  };

  const [activeSocial, setActiveSocial] = useState();
  const [moreSocialsPopUp, toggleMoreSocialsPopUp] = useState(false);

  useEffect(() => {
    addHandles();
  }, []);

  return (
    <div className={styles.Page1}>
      {activeSocial ? (
        <AddSocialPopUp
          data={activeSocial}
          closeFunction={() => setActiveSocial(null)}
          onSave={(value) => {
            activeSocial.value = value;
            addHandle({
              title: activeSocial.title,
              value: activeSocial.value,
            });
          }}
        />
      ) : null}
      {moreSocialsPopUp ? (
        <MoreSocialsPopUp
          closeFunction={() => toggleMoreSocialsPopUp(false)}
          addHandle={addMoreHandles}
          handlesPresent={socials}
        />
      ) : null}
      <h1>Add Socials</h1>
      <h4>Connect your socials</h4>

      <div className={styles.socialsBoxContainer}>
        {socials
          ? socials.map((value, index) => {
              return (
                <div
                  className={styles.social}
                  onClick={() => setActiveSocial(value)}
                >
                  <img src={value.img} alt="" />
                  <p>{value.title}</p>
                  {value.value ? <span>Edit</span> : null}
                </div>
              );
            })
          : null}
        <div
          className={styles.social}
          onClick={() => toggleMoreSocialsPopUp(true)}
        >
          <img
            src="	https://cdn-icons-png.flaticon.com/512/992/992651.png"
            alt=""
          />
          <p>Add more</p>
        </div>
      </div>
      <button
        className={styles.NextButton}
        onClick={() => {
          onSubmit(socials);
        }}
      >
        Next
      </button>
    </div>
  );
}
