import styles from './FTUEPage.module.css';

export default function Loading() {
  return (
    <div className={styles.Loading}>
      <div className={styles.loaderHeader}>
        <h1>Welcome to BondU</h1>
        <h4>Setting up your Digital Business Card</h4>
        <div className={styles.loadingioSpinner}>
          <div className={styles.ldio}>
            <div></div>
          </div>
        </div>
        <p>
           Over seven million trees are cut down each year for paper business
          cards
        </p>
      </div>
    </div>
  );
}
