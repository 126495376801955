import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1>BondU Privacy Policy</h1>
      <p>Effective Date: 7/9/2024</p>
      <p>
        Welcome to BondU, your trusted platform for creating and sharing digital
        business cards. We take your privacy seriously and are committed to
        protecting your personal information.
      </p>

      <section>
        <h2>1. Information We Collect</h2>
        <p>BondU may collect the following types of information:</p>
        <ul>
          <li>
            <h3>1.1. Personal Information</h3>
            <p>When you create a profile, we collect:</p>
            <ul>
              <li>Name (For identification)</li>
              <li>Designation (Professional role)</li>
              <li>Email Address (For contact and sharing)</li>
              <li>Phone Number (For communication and sharing)</li>
              <li>Social Media Handles (Optional, for connection)</li>
            </ul>
          </li>
          <li>
            <h3>1.2. Device Information</h3>
            <p>Includes:</p>
            <ul>
              <li>QRCode Sharing (Unique code for viewing your information)</li>
            </ul>
          </li>
          <li>
            <h3>1.3. Contact Information Access (With Permission)</h3>
            <p>Allows BondU to:</p>
            <ul>
              <li>Import contacts for easy sharing</li>
              <li>Save shared contacts from profiles</li>
              <li>Manage incoming contact leads</li>
            </ul>
          </li>
          <li>
            <h3>1.4. Notifications (With Consent)</h3>
            <p>Includes:</p>
            <ul>
              <li>New leads/contact requests</li>
              <li>App updates/changes</li>
            </ul>
            <p>(You can disable notifications anytime)</p>
          </li>
        </ul>
      </section>

      <section>
        <h2>2. How We Use Your Information</h2>
        <p>We use your information to:</p>
        <ul>
          <li>Create and personalize your digital business card</li>
          <li>Enable QR code sharing</li>
          <li>Facilitate and track shared leads</li>
          <li>Improve user experience with relevant notifications/updates</li>
          <li>Provide customer support</li>
        </ul>
        <p>
          We do not sell, trade, or rent your personal information to third
          parties.
        </p>
      </section>

      <section>
        <h2>3. How We Share Your Information</h2>
        <p>We may share your information in these instances:</p>
        <ul>
          <li>
            <h3>Sharing Your Business Card</h3>
            <p>
              Recipients will have access to the information you include (name,
              designation, etc.).
            </p>
          </li>
          <li>
            <h3>With Your Consent</h3>
            <p>
              We may share for other purposes with your explicit permission.
            </p>
          </li>
        </ul>
        <p>
          We do not share your information with third parties for marketing
          without your consent.
        </p>
      </section>

      <section>
        <h2>4. How We Protect Your Information</h2>
        <p>
          We implement appropriate security measures to protect your personal
          data from unauthorized access, alteration, disclosure, or destruction.
            These measures include encryption and secure storage of user data.
        </p>
        <p>
          However, please be aware that no security system is impenetrable, and
          we cannot guarantee the absolute security of your data. You are
          responsible for maintaining the   security of your device and access
          to the BondU app.
        </p>
      </section>

      <section>
        <h2>5. Your Rights and Choices</h2>
        <p>You have the following rights regarding your personal data:</p>
        <ul>
          <li>
            <h3>Access</h3>
            <p>
              You can access and review the information you have provided at any
              time within the app.
            </p>
          </li>
          <li>
            <h3>Correction</h3>
            <p>
              You can update or correct your personal information through the
              app settings.
            </p>
          </li>
          <li>
            <h3>Deletion</h3>
            <p>
              You can delete your account, and we will erase your profile and
              all associated information from our servers.
            </p>
          </li>
          <li>
            <h3>Notification Preferences</h3>
            <p>
              You can manage notification preferences through your device or app
              settings.
            </p>
          </li>
        </ul>
        <p>
          To exercise any of these rights, you can contact us at
          bondu.dbc@gmail.com.
        </p>
      </section>
      <section>
        <h2>6. Permissions Requested by BondU</h2>
        <p>
          To provide you with the best user experience, BondU requests the
          following permissions:
        </p>
        <ul>
          <li>
            <h3>6.1. Contacts Access</h3>
            <p>
              We request permission to access your contact list. This enables
              you to:
            </p>
            <ul>
              <li>
                Easily share your digital business card with your contacts
              </li>
              <li>Accept leads and add them directly to your contact list</li>
            </ul>
            <p>
              You can deny or revoke this permission at any time through your
              device settings, although this may limit certain functionalities
              of the app.
            </p>
          </li>
          <li>
            <h3>6.2. Notifications</h3>
            <p>
              We request permission to send you notifications regarding new
              leads, updates, and important information related to your use of
              the BondU app. You can manage or disable notifications in your
              device settings.
            </p>
          </li>
        </ul>
      </section>

      <section>
        <h2>7. Third-Party Services</h2>
        <p>
          The BondU app may contain links to third-party services or social
          media platforms (such as when sharing your social handles). We are not
          responsible for the privacy practices of third-party services, and we
          encourage you to read their privacy policies.
        </p>
      </section>
      <section>
        <h2>8. Data Retention</h2>
        <p>
          We retain your personal information for as long as your account is
          active or as needed to provide you with services. If you   choose to
          delete your account, we will delete your data promptly, except where
          we are required by law to retain it.
        </p>
      </section>

      <section>
        <h2>9. Changes to This Privacy Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. When we do, we
          will revise the "Effective Date" at the top of this policy. Any
          changes will be posted in the app, and we encourage you to review this
          policy regularly to stay informed about how we are protecting your
          information.
        </p>
      </section>

      <section>
        <h2>10. Contact Us</h2>
        <p>If you have any questions, please contact us at:</p>
        <p>Email: bondu.dbc@gmail.com</p>
      </section>

      <p>
        By using BondU, you acknowledge that you have read, understood, and
        agreed to this Privacy Policy.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
