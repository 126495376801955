import { useState } from 'react';
import BusinessCard from '../UserView/BusinessCard';
import styles from './FTUEPage.module.css';
import Page1 from './Page1';
import Page2 from './Page2';
import Page3 from './Page3';
import Loading from './Loading';
import { updateUserDetails } from '../../services/apiHelper';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

export default function FTUEPage() {
  const nav = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  console.log(searchParams.get('phone'));
  const [data, setData] = useState({
    name: '',
    designation: '',
    phone: searchParams.get('phone'),
    email: '',
    socialMediaHandles: {},
  });

  const [currentPage, setCurrentPage] = useState(0);
  const pages = [
    <Page1
      initialData={data}
      onSubmit={(name, email) => {
        data['name'] = name;
        data['email'] = email;
        console.log(data);
        setCurrentPage(1);
      }}
    />,
    <Page2
      initialData={data}
      onSubmit={(companyName, designation) => {
        data['companyName'] = companyName;
        data['designation'] = designation;
        setCurrentPage(2);
        console.log(data);
      }}
    />,
    <Page3
      initialData={data}
      addHandle={(handle) => {
        data.socialMediaHandles[handle.title] = handle.value;
        console.log(data);
      }}
      onSubmit={async (socialMediaHandles) => {
        socialMediaHandles.forEach((element) => {
          data.socialMediaHandles[element.title] = element.value;
        });
        toggleLoading(true);
        console.log(data);
        const response = await updateUserDetails(data);
        console.log(response);
        if (response === 200) {
          nav(`/dashboard`);
        } else if (response === 403) {
          alert('Session logged out');
          nav(`/`);
        }
      }}
    />,
  ];

  const [loading, toggleLoading] = useState(false);

  return !loading ? (
    <div className={styles.FTUEPage}>
      <div className={styles.left_pane}>
        <div className={styles.nav_links}>
          <img
            className={styles.primary_color}
            onClick={() => setCurrentPage(Math.max(currentPage - 1, 0))}
            src="https://cdn-icons-png.flaticon.com/512/3114/3114883.png"
            alt=""
          />
          <p className={styles.primary_color}>Personal Info</p>
          <p className={currentPage >= 1 ? styles.primary_color : ''}>
            {' '}
            {' > '} Company Info
          </p>
          <p className={currentPage >= 2 ? styles.primary_color : ''}>
            {' '}
            {' > '} Add Socials
          </p>
        </div>
        {pages[currentPage]}
      </div>
      <div className={styles.right_pane}>
        <h2>BondU</h2>
        <div className={styles.BusinessCard}>
          <BusinessCard data={data} />
        </div>

        <p>
          Do you also feel paper business cards are frustrating to carry or in
          digitizing. <br /> <br />
          We bring you <b>BondU</b> which is a one stop solution for all
        </p>
      </div>
    </div>
  ) : (
    <Loading />
  );
}
