import styles from './styles.module.css';

export default function CommingSoonPage({ toggleSidebar }) {
  return (
    <div
      className={styles.CommingSoon}
      onClick={() => {
        toggleSidebar(false);
      }}
    >
      <div className={styles.centerBox}>
        <br />
      </div>
      <h3>Comming Soon</h3>
      <div className={styles.spaceMaker}>
        <br />
      </div>
    </div>
  );
}
