import { useRef, useState } from 'react';
import styles from '../FTUE/FTUEPage.module.css';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { createCanvasPreview } from '../../services/utils';

const MIN_DIMENSION = 100;
const MAX_FILE_SIZE = 10000000;

export default function EditProfilePhotoPopup({ togglePopup, saveImage }) {
  const inputRef = useRef();
  const canvasRef = useRef();
  const imgRef = useRef();
  const [imgSrc, setImgSrc] = useState('');
  const [crop, setCrop] = useState();
  const [fileSizeExceeded, setFileSizeExceededAlert] = useState(false);

  const openImagePicker = () => {
    inputRef.current.click();
  };

  const onImageSelected = (e) => {
    const file = e.target.files?.[0];

    if (!file) return;

    if (file.size > MAX_FILE_SIZE) {
      setFileSizeExceededAlert(true);
      return;
    } else {
      setFileSizeExceededAlert(false);
    }

    const reader = new FileReader();

    reader.addEventListener('load', () => {
      const imgUrl = reader.result?.toString() || '';

      const imageElement = new Image();
      imageElement.src = imgUrl;

      imageElement.addEventListener('load', (e) => {
        const { naturalHeight, naturalWidth } = e.currentTarget;
        if (naturalHeight < MIN_DIMENSION || naturalWidth < MIN_DIMENSION) {
          alert(
            `Image should be atleast ${MIN_DIMENSION} x ${MIN_DIMENSION} pixels large`
          );
          setImgSrc('');
          return;
        }
      });

      setImgSrc(imgUrl);
    });

    reader.readAsDataURL(file);
  };

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;

    const crop = makeAspectCrop(
      {
        unit: 'px',
        width: MIN_DIMENSION,
      },
      1,
      width,
      height
    );

    const centeredCrop = centerCrop(crop, width, height);

    setCrop(centeredCrop);
  };

  const handleSaveImage = () => {
    createCanvasPreview(imgRef.current, canvasRef.current, crop);

    const dataUrl = canvasRef.current.toDataURL('image/jpeg');
    saveImage(dataUrl);
  };

  return (
    <div
      className={'EditImagePopUp ' + styles.EditImagePopUp + ' ' + styles.popup}
    >
      <img
        className="ClosePopUpButton"
        onClick={togglePopup}
        src="https://cdn-icons-png.flaticon.com/512/2997/2997911.png"
        alt=""
      />
      <h3>{imgSrc ? 'Crop or Resize photo' : 'Add Profile Photo'}</h3>
      <div className={styles.uploadBox}>
        {imgSrc ? (
          <ReactCrop
            keepSelection={true}
            crop={crop}
            circularCrop={true}
            onChange={(pixelCrop) => {
              setCrop(pixelCrop);
            }}
            aspect={1}
            minWidth={MIN_DIMENSION}
          >
            <img
              ref={imgRef}
              src={imgSrc}
              alt="Upload"
              className={styles.imgCrop}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        ) : (
          <img
            className={styles.uploadImageIcon}
            src="	https://cdn-icons-png.flaticon.com/512/4131/4131814.png"
            alt=""
          />
        )}
        {!imgSrc ? (
          <>
            <button className={styles.uploadButton} onClick={openImagePicker}>
              <div>
                <img
                  src="	https://cdn-icons-png.flaticon.com/512/3097/3097412.png"
                  alt=""
                />
                <p>Select image file</p>
              </div>
            </button>
            <p className={styles.uploadDesc}>
              upload any jpg, png file under 10 Mb
              {fileSizeExceeded ? (
                <span className={styles.alertText}> *file size exceeded</span>
              ) : (
                ''
              )}
            </p>
          </>
        ) : null}
      </div>

      <input
        type="file"
        ref={inputRef}
        name="profile"
        id="profile"
        onChange={onImageSelected}
      />
      <br />
      {crop && <canvas className={styles.cropCanvas} ref={canvasRef} />}
      <div>
        {imgSrc ? (
          <div className={styles.cropImageFooter}>
            <p className={styles.uploadDesc}>
              Adjust the handles around the circle to crop or resize the image
            </p>
            <button className={styles.SaveImgButton} onClick={handleSaveImage}>
              Save
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
}
