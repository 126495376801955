import React from 'react';
import './PrivacyPolicy.css';

const CancellationRefund = () => {
  return (
    <div className="privacy-policy">
      <h1>BondU Cancellation and Refund Policy</h1>
      <p>Effective Date: 7/9/2024</p>
      <p>
        Thank you for using BondU! This Cancellation and Refund Policy explains
        your rights regarding subscription cancellations and refunds
      </p>

      <section>
        <h2>1. Cancellation of Subscription</h2>
        <p>
          You may cancel your BondU subscription at any time by following the
          cancellation steps provided in the app settings. Upon cancellation:
        </p>
        <ul>
          <li>
            Youwill continue to have access to BondU's paid features until the
            end of your current billing cycle.
          </li>
          <li>
            Nofurther charges will be made after the current billing cycle ends.
          </li>
        </ul>
      </section>

      <section>
        <h2>2. Refund Policy</h2>
        <p>
          BondU offers a no-refund policy on subscription fees once payment has
          been made, except under the following circumstances:
        </p>
        <ul>
          <li>
            Technical Errors: If a technical issue with BondU caused an
            incorrect or duplicate charge, you may be eligible for a full
            refund.
          </li>
          <li>
            Service Disruption: If BondU is unable to provide services for an
            extended period (over 72 hours) due to issues under our control, you
            may request a prorated refund for the period of service
            interruption.
          </li>
        </ul>
      </section>

      <section>
        <h2>3. How to Request a Refund</h2>
        <p>
          If you believe you are eligible for a refund based on the reasons
          outlined above, please contact us at
        </p>
        <ul>
          <li>Email: bondu.dbc@gmail.com</li>
          <li>
            Include your account details and a brief explanation of the issue.
          </li>
        </ul>
        <p>
          We will review your request within 7 business days and notify you of
          the outcome. Approved refunds will be processed within 10 business
          days and credited back to your original payment method
        </p>
      </section>

      <section>
        <h2>4. Non-Refundable Circumstances</h2>
        <p>Refunds are not provided under the following conditions:</p>
        <ul>
          <li>Change of mind after subscription purchase.</li>
          <li>Failure to cancel your subscription before the renewal date.</li>
          <li>Partial use of a subscription.</li>
        </ul>
      </section>

      <section>
        <h2>5. Subscription Modifications</h2>
        <p>
          You may upgrade or downgrade your BondU subscription at any time. If
          you downgrade, the change will take effect at the end of the current
          billing cycle. If you upgrade, you will be charged immediately for the
          new plan, with a prorated amount for the remaining period.
        </p>
      </section>
      <section>
        <h2>6. Trial Periods</h2>
        <p>
          If BondU offers a free trial period, you can cancel your subscription
          before the trial ends to avoid any charges. Once the trial period is
          over, your selected payment method will be charged for the applicable
          subscription fee unless you cancel before the trial period ends.
        </p>
      </section>

      <section>
        <h2>7. Contact for Cancellation or Refund Queries</h2>
        <p>
          If you have any questions about canceling your subscription or
          requesting a refund, please contact our support team at:
        </p>
        <ul>
          <li> Email: bondu.dbc@gmail.com</li>
        </ul>
      </section>
    </div>
  );
};

export default CancellationRefund;
