import { useEffect, useState } from 'react';
import { getUserDetails } from '../../services/apiHelper';
import { useParams } from 'react-router-dom';
import LoadingPage from '../LoadingPage/LoadingPage';
import { socialMediaHandlesDict } from '../../constants';
import './UserView.css';
import SendContactPopup from './SendContactPopup';
import SocialLink from './SocialLink';
function UserView() {
  const [data, setData] = useState({
    name: '',
    designation: '',
    phone: 123456,
    email: '',
    companyName: '',
    socialMediaHandles: {},
  });

  const [dc, setDc] = useState(null);

  const { userID } = useParams();

  async function getData(id) {
    const response = await getUserDetails(id);
    setData(response);
    if (response.name) {
      document.title = `${response.name} | ${response.designation}`;
    }

    const metaDescription = document.querySelector('meta[name="description"]');
    metaDescription.content = 'This is my Digital Business Card From BondU';
    console.log(response);
  }

  useEffect(() => {
    getData(userID);
  }, [userID]);

  const [contactPopup, setContactPopup] = useState(false);

  function toggleContactPopup() {
    setContactPopup(!contactPopup);
    if (!contactPopup) {
      // Disable scroll
      document.body.style.overflow = 'hidden';
    } else {
      // Enable scroll
      document.body.style.overflow = 'auto';
    }
  }

  function downloadVCF() {
    const vCardData = `BEGIN:VCARD
VERSION:3.0
FN: ${data.name}
TITLE: ${data.designation}
ORG:${data.companyName}
EMAIL:${data.email}
TEL:${data.phone}
END:VCARD`;

    const element = document.createElement('a');
    const file = new Blob([vCardData], { type: 'text/vcard' });
    element.href = URL.createObjectURL(file);
    element.download = `${data.name}.vcf`;
    document.body.appendChild(element);
    element.click();
  }
  useEffect(() => {
    if (data.designation && data.companyName) {
      setDc(
        <div class="text--0- UserDesignation">
          {data.designation}:{data.companyName}
        </div>
      );
    } else if (data.designation) {
      setDc(<div class="text--0- UserDesignation">{data.designation}</div>);
    } else if (data.companyName) {
      setDc(<div class="text--0- UserDesignation">{data.companyName}</div>);
    }
  }, []);
  return data.phone ? (
    <div>
      {contactPopup ? (
        <SendContactPopup toggle={toggleContactPopup} userID={userID} />
      ) : null}
      <div className={`UserView ${contactPopup ? 'blur-background' : ''}`}>
        <div
          className="ProfileImage"
          style={{
            background: `linear-gradient(180deg, rgba(0, 0, 0, 0.00) 45.84%, rgba(0, 0, 0, 0.70) 100%), 
                url(${
                  data.profilePhoto ?? '/user.png'
                }) lightgray -109.105px -58.519px / 158.428% 215.528% no-repeat`,
          }}
        >
          <div class="text--0- UserName">{data.name}</div>
          {data.designation && data.companyName ? (
            <div class="text--0- UserDesignation">
              {data.designation} {data.companyName}
            </div>
          ) : (
            <div class="text--0- UserDesignation"></div>
          )}
          <div class="container--0-">
            <button
              className="UserViewButton save"
              onClick={() => {
                downloadVCF();
              }}
            >
              <img
                src="https://cdn-icons-png.flaticon.com/512/10307/10307706.png"
                alt=""
              />
              Save
            </button>
            <button
              className="UserViewButton connect"
              onClick={toggleContactPopup}
            >
              <img
                src="https://cdn-icons-png.flaticon.com/512/10307/10307706.png"
                alt=""
              />
              Connect
            </button>
          </div>
        </div>
        {data.bio && (
          <div className="about-me">
            <div className="about-me-heading">About Me</div>
            <div className="data-bio">{data.bio}</div>
          </div>
        )}
        <div class="container--1-">
          <button
            className="callemail"
            onClick={(e) => {
              e.preventDefault();
              window.location.href = `tel:${data.phone}`;
            }}
          >
            <img
              src="	https://cdn-icons-png.flaticon.com/512/126/126509.png"
              alt=""
            />
            Call
          </button>

          <button
            className="callemail"
            onClick={(e) => {
              e.preventDefault();
              window.location.href = `mailto:${data.email}`;
            }}
          >
            <img
              src="https://cdn-icons-png.flaticon.com/512/11502/11502423.png"
              alt=""
            />
            Email
          </button>
        </div>
        {data.socialMediaHandles ? (
          <div className="handles">
            {data.socialMediaHandles['Web'] ? (
              <SocialLink
                title={'Website'}
                url={data.socialMediaHandles['Web']}
                icon={socialMediaHandlesDict['Web']}
              />
            ) : null}
            {data.socialMediaHandles['Maps'] ? (
              <SocialLink
                title={'Maps'}
                url={data.socialMediaHandles['Maps']}
                icon={socialMediaHandlesDict['Maps']}
              />
            ) : null}

            {data.socialMediaHandles['Whatsapp'] ? (
              <SocialLink
                title={'Whatsapp'}
                url={`https://wa.me/+91${data.socialMediaHandles['Whatsapp']}`}
                icon={socialMediaHandlesDict['Whatsapp']}
              />
            ) : null}
            {data.socialMediaHandles['Instagram'] ? (
              <SocialLink
                title={'Instagram'}
                url={data.socialMediaHandles['Instagram']}
                icon={socialMediaHandlesDict['Instagram']}
              />
            ) : null}
            {data.socialMediaHandles['LinkedIn'] ? (
              <SocialLink
                title={'LinkedIn'}
                url={data.socialMediaHandles['LinkedIn']}
                icon={socialMediaHandlesDict['LinkedIn']}
              />
            ) : null}
            {data.socialMediaHandles['Pinterest'] ? (
              <SocialLink
                title={'Pinterest'}
                url={data.socialMediaHandles['Pinterest']}
                icon={socialMediaHandlesDict['Pinterest']}
              />
            ) : null}
            {data.socialMediaHandles['Facebook'] ? (
              <SocialLink
                title={'Facebook'}
                url={data.socialMediaHandles['Facebook']}
                icon={socialMediaHandlesDict['Facebook']}
              />
            ) : null}
            {data.socialMediaHandles['YouTube'] ? (
              <SocialLink
                title={'YouTube'}
                url={data.socialMediaHandles['YouTube']}
                icon={socialMediaHandlesDict['YouTube']}
              />
            ) : null}
            {data.socialMediaHandles['Snapchat'] ? (
              <SocialLink
                title={'Snapchat'}
                url={data.socialMediaHandles['Snapchat']}
                icon={socialMediaHandlesDict['Snapchat']}
              />
            ) : null}
            {data.socialMediaHandles['Behance'] ? (
              <SocialLink
                title={'Behance'}
                url={data.socialMediaHandles['Behance']}
                icon={socialMediaHandlesDict['Behance']}
              />
            ) : null}
            {data.socialMediaHandles['X'] ? (
              <SocialLink
                title={'X'}
                url={data.socialMediaHandles['X']}
                icon={socialMediaHandlesDict['X']}
              />
            ) : null}
            {data.socialMediaHandles['Dribbble'] ? (
              <SocialLink
                title={'Dribbble'}
                url={data.socialMediaHandles['Dribbble']}
                icon={socialMediaHandlesDict['Dribbble']}
              />
            ) : null}
          </div>
        ) : null}
        <div
          style={{
            width: '100%',
            height: '82px',
            backgroundColor: 'rgba(155, 81, 224, 0.10)',
            alignContent: 'center',
          }}
        >
          <button
            className="BondUButton"
            onClick={(e) => {
              e.preventDefault();
              window.location.href = 'http://www.app.bondu.in/';
            }}
          >
            Create Your BondU Card
          </button>
        </div>
      </div>
    </div>
  ) : (
    <LoadingPage />
  );
}
export default UserView;
