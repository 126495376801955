import { compress, dataURItoBlob } from './utils';

export async function addContact(userID, contact) {
  const token = localStorage.getItem('sessionToken');
  try {
    const response = await fetch(`https://server.bondu.in/profile/addContact`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: token },
      body: JSON.stringify({
        userID: userID,
        contact: contact,
      }),
    });
    return response.status;
  } catch (err) {
    console.log(err);
    return 500;
  }
}

export async function getUserDetails(userID) {
  try {
    const response = await fetch(`https://server.bondu.in/user/${userID}`);
    if (response.status === 400) {
      return {
        name: 'Unknown',
        bio: 'User does not exist',
      };
    }
    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
    return null;
  }
}

export async function updateUserDetails(userData) {
  const userID = localStorage.getItem('_id');
  const token = localStorage.getItem('sessionToken');
  try {
    const response = await fetch(
      `https://server.bondu.in/user/updateUser/${userID}`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: token },
        body: JSON.stringify(userData),
      }
    );
    return response.status;
  } catch (err) {
    console.log(err);
    return 500;
  }
}

export async function getUserID(navigateCallback, token) {
  try {
    const response = await fetch(
      `https://server.bondu.in/user/authenticate/phone`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      }
    );

    const data = await response.json();
    console.log(data);
    localStorage.setItem('_id', data['_id']);
    localStorage.setItem('sessionToken', data['sessionToken']);
    navigateCallback();
  } catch (err) {
    console.log(err);
  }
}

export async function uploadProfileImage(imageDataURL) {
  const token = localStorage.getItem('sessionToken');
  var blobData = dataURItoBlob(imageDataURL);

  // const blob = new Blob([imageDataURL], { type: 'image/jpeg' });

  const compressedImage = await compress(blobData);
  if (compressedImage === -1) {
    alert('File size too large!');
    return -1;
  }

  // const imgFile = blobToFile(blob, 'image');
  // console.log(imgFile);

  const formData = new FormData();
  formData.append('image', compressedImage);
  formData.append('_id', localStorage.getItem('_id'));

  try {
    const response = await fetch(
      `https://server.bondu.in/user/uploadProfileImage`,
      {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: token,
        },
      }
    );

    return 0;
  } catch (error) {
    console.log(error);
    return -1;
  }
}

export async function getContacts() {
  const userID = localStorage.getItem('_id');
  const token = localStorage.getItem('sessionToken');

  try {
    const response = await fetch(
      `https://server.bondu.in/profile/getContacts/${userID}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: token },
      }
    );
    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
    return 500;
  }
}
