import { useEffect, useState } from 'react';
import './LoginPage.css';
import { useNavigate } from 'react-router-dom';
import { getUserDetails, getUserID } from '../../services/apiHelper';
import { initOTPless } from '../../services/utils';
import LoadingPage from '../LoadingPage/LoadingPage';

export default function LoginPage() {
  const [isLoading, toggleLoading] = useState(false);
  const nav = useNavigate();

  const handleSubmit = async (e) => {
    toggleLoading(true);

    const userID = localStorage['_id'];
    const response = await getUserDetails(userID);
    if (!response) {
      nav('/');
      return;
    }
    if (response) {
      console.log(response);
      if (response.name) {
        nav(`/dashboard/`);
      } else {
        nav(`/ftue?phone=${response.phone}`);
      }
    }
  };

  useEffect(() => {
    console.log('Rendered');
    if (
      localStorage.getItem('nekoTssel-PTO') &&
      localStorage.getItem('sessionToken')
    ) {
      nav('/dashboard');
    }
    initOTPless(handleSubmit);

    window.otpless = (otplessUser) => {
      console.log(otplessUser);
      getUserID(handleSubmit, otplessUser['token']);
    };
  }, []);

  return !isLoading ? (
    <div className="LoginPage">
      <div id="otpless-login-page"></div>
    </div>
  ) : (
    <LoadingPage />
  );
}
