export default function TextField({ title, data, id }) {
  return (
    <div className="TextField">
      <label htmlFor="">{title}</label>
      <br />
      <input
        type="text"
        name={title}
        placeholder={title}
        onChange={(e) => {
          data[id] = e.target.value;
        }}
      />
    </div>
  );
}
