import { useNavigate } from 'react-router-dom';
import {
  getUserDetails,
  updateUserDetails,
  uploadProfileImage,
} from '../../services/apiHelper';
import InputField from './InputField';
import './ProfilePage.css';
import { useEffect, useState } from 'react';
import EditProfilePhotoPopup from './EditProfilePhotoPopup';
import LoadingPage from '../LoadingPage/LoadingPage';
import styles from './ProfilePage.module.css';
import AddSocialPopUp from '../FTUE/AddSocialPopUp';
import MoreSocialsPopUp from '../FTUE/MoreSocialsPopUp';
import { socialMediaHandlesDict } from '../../constants';

export default function ProfilePage({
  data,
  toggleEditProfile,
  toggleSidebar,
}) {
  const userID = localStorage.getItem('_id');

  const [profileImageURL, setProfileImageURL] = useState();
  const [editPhotoPopUp, setEditPhotoPopUp] = useState(false);
  const [isLoading, setLoadingState] = useState(false);
  const [userDetails, setUserDetails] = useState();
  const [socialMediaHandles, setSocialMediaHandles] = useState([]);
  const [activeSocial, setActiveSocial] = useState();
  const [moreSocialsPopUp, toggleMoreSocialsPopUp] = useState(false);

  const nav = useNavigate();
  if (!userID) {
    nav('/login');
  }

  const addMoreHandles = (title, imgURL) => {
    socialMediaHandles.push({
      title: title,
      img: imgURL,
      value: null,
    });

    setSocialMediaHandles(socialMediaHandles);
    setActiveSocial(socialMediaHandles.at(socialMediaHandles.length - 1));
    toggleMoreSocialsPopUp(false);
  };

  const getData = (userID) => {
    setUserDetails(data);
    setProfileImageURL(
      data.profilePhoto ??
        'https://cdn-icons-png.flaticon.com/512/13404/13404092.png'
    );
    setSocialMediaHandles([]);
    const handles = data.socialMediaHandles;
    for (var key in handles) {
      if (handles.hasOwnProperty(key) && data.socialMediaHandles[key] != null) {
        socialMediaHandles.push({
          title: key,
          img: socialMediaHandlesDict[key],
          value: data.socialMediaHandles[key] ?? null,
        });
      }
    }
    setSocialMediaHandles(socialMediaHandles);
    console.log(data);
  };

  const togglePopup = () => {
    setEditPhotoPopUp(!editPhotoPopUp);
  };

  const saveData = async () => {
    setLoadingState(true);
    socialMediaHandles.forEach((element) => {
      userDetails.socialMediaHandles[element.title] = element.value;
    });
    console.log(userDetails);
    const response = await updateUserDetails(userDetails);
    console.log(response);
    if (response === 200) {
      alert('Data updated successfully');
    } else if (response === 403) {
      alert('Session logged out');
      logOut();
    }
    setLoadingState(false);
  };

  const saveImage = async (dataUrl) => {
    setLoadingState(true);
    const result = await uploadProfileImage(dataUrl);
    if (result === 0) {
      console.log('success');
      setProfileImageURL(dataUrl);
      userDetails['profileImage'] = dataUrl;
    }

    togglePopup();
    setLoadingState(false);
  };

  const logOut = () => {
    setLoadingState(true);
    localStorage.clear();
    nav('/login');
  };

  useEffect(() => {
    getData(userID);
  }, []);

  useEffect(() => {
    const html = document.querySelector('html');
    if (html) {
      html.style.overflow =
        activeSocial || editPhotoPopUp || moreSocialsPopUp ? 'hidden' : 'auto';
    }
  }, [activeSocial, editPhotoPopUp, moreSocialsPopUp]);

  if (userDetails && !isLoading) {
    return (
      <div className={styles.root} onClick={() => toggleSidebar(false)}>
        {activeSocial || editPhotoPopUp || moreSocialsPopUp ? (
          <div
            className={styles.overlay}
            onClick={() => {
              setActiveSocial(null);
              setEditPhotoPopUp(false);
              toggleMoreSocialsPopUp(false);
            }}
          ></div>
        ) : null}
        {activeSocial ? (
          <AddSocialPopUp
            data={activeSocial}
            closeFunction={() => setActiveSocial(null)}
            onSave={(value) => {
              activeSocial.value = value;
            }}
          />
        ) : null}
        {moreSocialsPopUp ? (
          <MoreSocialsPopUp
            closeFunction={() => toggleMoreSocialsPopUp(false)}
            addHandle={addMoreHandles}
            handlesPresent={socialMediaHandles}
          />
        ) : null}
        {editPhotoPopUp ? (
          <EditProfilePhotoPopup
            togglePopup={togglePopup}
            saveImage={saveImage}
          />
        ) : null}
        <div className="ProfilePageImage">
          <img
            src={
              profileImageURL ??
              'https://cdn-icons-png.flaticon.com/512/1144/1144811.png'
            }
            alt=""
          />
          <button className="EditImageButton" onClick={togglePopup}>
            <img
              src="	https://cdn-icons-png.flaticon.com/512/14070/14070496.png"
              alt=""
            />
          </button>
        </div>
        <div className={styles.rightDiv}>
          <div className={styles.header}>
            <div>
              <img
                className={styles.backButton}
                onClick={() => toggleEditProfile(false)}
                src="https://cdn-icons-png.flaticon.com/512/3114/3114883.png"
                alt=""
              />
              <h2>Edit Profile</h2>
            </div>

            <button
              className={styles.updateChangesButton}
              onClick={() => {
                console.log(socialMediaHandles);
                saveData();
              }}
            >
              Update Changes
            </button>
          </div>

          <div className="EditForm">
            <InputField title={'Name'} id={'name'} userDetail={userDetails} />
            <InputField
              title={'Designation'}
              userDetail={userDetails}
              id={'designation'}
            />
            <InputField
              title={'Company'}
              id={'companyName'}
              userDetail={userDetails}
            />
            <InputField title={'About'} id={'bio'} userDetail={userDetails} />
            <InputField title={'Phone'} id={'phone'} userDetail={userDetails} />
            <InputField title={'Email'} id={'email'} userDetail={userDetails} />
          </div>
          <div className={styles.socialsDiv}>
            <h3>Add Socials</h3>
            <div className={styles.socialsBoxContainer}>
              {socialMediaHandles.map((value, index) => {
                return (
                  <div
                    key={index}
                    className={styles.social}
                    onClick={() => setActiveSocial(value)}
                  >
                    <img src={value.img} alt="" />
                    <p>{value.title}</p>
                    {value.value ? <span>Edit</span> : null}
                  </div>
                );
              })}
              <div
                className={styles.social}
                onClick={() => {
                  toggleMoreSocialsPopUp(true);
                }}
              >
                <img
                  src="	https://cdn-icons-png.flaticon.com/512/992/992651.png"
                  alt=""
                />
                <p>Add more</p>
              </div>
            </div>
          </div>
          <div className={styles.submitDiv}>
            <button
              className={styles.updateChangesButton}
              onClick={() => {
                console.log(socialMediaHandles);
                saveData();
              }}
            >
              Update Changes
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return <LoadingPage />;
  }
}
