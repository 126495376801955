import Compressor from 'compressorjs';

export const initOTPless = (callback) => {
  const otplessInit = Reflect.get(window, 'otplessInit');

  const loadScript = () => {
    const script = document.createElement('script');
    script.src = 'https://otpless.com/v2/auth.js';
    script.id = 'otpless-sdk';
    script.setAttribute('data-appid', 'F2TNNO2588FRIFI3QJ3V');
    document.body.appendChild(script);
  };

  otplessInit ? otplessInit() : loadScript();

  Reflect.set(window, 'otpless', callback);
};

export function blobToFile(blob, fileName) {
  return new File([blob], fileName, { type: blob.type });
}

export const compress = async (imgBlob) => {
  var quality;

  const file = blobToFile(imgBlob, 'image.jpg');
  if (file.size < 4000000) {
    quality = 1;
  } else if (file.size < 10000000) {
    quality = 0.8;
  } else if (file.size < 20000000) {
    quality = 0.6;
  } else if (file.size < 40000000) {
    quality = 0.4;
  } else {
    return -1;
  }

  var image = new Promise(function (resolve, reject) {
    var compressor = new Compressor(file, {
      quality: quality,
      success(result) {
        const file = new File([result], 'image.jpg', { type: 'image/jpeg' });
        console.log(file);
        resolve(file);
      },
      error(err) {
        console.log(err.message);
        reject(err);
      },
    });
  });

  return await image;
  // return compressedFile;
  // const compressedFile = await compressImage(file, setting);
};

export const createCanvasPreview = (image, canvas, crop) => {
  console.log(canvas);

  const ctx = canvas.getContext('2d');
  if (!ctx) {
    throw new Error('No 2D context');
  }

  const pixelRatio = window.devicePixelRatio;
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = 'high';
  ctx.save();

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  ctx.translate(-cropX, -cropY);
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight
  );

  ctx.restore();
};

export function dataURItoBlob(dataURI) {
  var binary = atob(dataURI.split(',')[1]);
  var array = [];
  for (var i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
}
