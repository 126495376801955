import styles from './DashboardPage.module.css';
import BusinessCard from '../UserView/BusinessCard';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getContacts, getUserDetails } from '../../services/apiHelper';
import ProfilePage from '../ProfilePage/ProfilePage';
import adPhoto from '../../assets/images/ad.png';
import LoadingPage from '../LoadingPage/LoadingPage';
import Sidebar from '../../components/Sidebar';
import CommingSoonPage from '../CommingSoonPage/CommingSoonPage';

export default function DashboardPage() {
  const nav = useNavigate();
  const userID = localStorage.getItem('_id');

  const [userDetails, setUserDetails] = useState({
    name: 'Name',
    designation: '',
    phone: 123456,
    email: '',
    socialMediaHandles: {},
  });
  const [contacts, setContacts] = useState();
  const [profileImage, setProfileImage] = useState();
  const [editProfile, toggleEditProfile] = useState(false);
  const [sidebar, toggleSidebar] = useState(false);
  const [isLoading, setLoadingState] = useState(false);
  const svgRef = useRef();
  const [qr, setqr] = useState(null);
  const [menu, setMenu] = useState(0);

  function triggerDownload(imgURI) {
    const a = document.createElement('a');
    a.download = 'MY_QR.png'; // filename
    a.target = '_blank';
    a.href = imgURI;

    // trigger download button
    // (set `bubbles` to false here.
    // or just `a.click()` if you don't care about bubbling)
    a.dispatchEvent(
      new MouseEvent('click', {
        view: window,
        bubbles: false,
        cancelable: true,
      })
    );
  }

  const downloadQR = () => {
    console.log(svgRef.current);
    const svgElement = svgRef.current;

    const svgData = new XMLSerializer().serializeToString(svgElement);
    const blob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
    console.log(blob);
    setqr(URL.createObjectURL(blob));

    const image = new Image();

    image.width = svgElement.width.baseVal.value;
    image.height = svgElement.height.baseVal.value;
    image.src = qr;

    image.onload = function () {
      const canvas = document.getElementById('canvas');
      canvas.width = image.width;
      canvas.height = image.height;

      const ctx = canvas.getContext('2d');
      ctx.drawImage(image, 0, 0);
      URL.revokeObjectURL(qr);

      const imgURI = canvas
        .toDataURL('image/png')
        .replace('image/png', 'image/octet-stream');
      triggerDownload(imgURI);
    };
  };

  const getData = async (userID) => {
    setLoadingState(true);
    const response = await getUserDetails(userID);
    if (!response) {
      nav('/');
      return;
    }
    if (response) {
      response['socialMediaHandles'] = response['socialMediaHandles'] ?? {};
      setUserDetails(response);
    }

    console.log(response['profilePhoto']);
    setProfileImage(
      response['profilePhoto'] ??
        'https://cdn-icons-png.flaticon.com/512/149/149071.png'
    );

    const response2 = await getContacts();
    setContacts(response2);
    console.log(response2);
    setLoadingState(false);
  };

  useEffect(() => {
    if (userID) {
      getData(userID);
    } else {
      console.log('asdf');
    }
  }, []);

  return userDetails.name !== 'Name' ? (
    <div className={styles.root}>
      <Sidebar
        sidebar={sidebar}
        setSidebar={toggleSidebar}
        menu={menu}
        setMenu={setMenu}
      />
      {editProfile ? (
        <ProfilePage
          toggleEditProfile={toggleEditProfile}
          data={userDetails}
          toggleSidebar={toggleSidebar}
        />
      ) : menu === 0 ? (
        <div className={styles.rightDiv} onClick={() => toggleSidebar(false)}>
          <h2>Home</h2>

          <div className={styles.intro}>
            <img
              src={
                profileImage ??
                '	https://cdn-icons-png.flaticon.com/512/13404/13404092.png'
              }
              alt=""
            />
            <div>
              <h1>
                Welcome {userDetails.name ? userDetails.name.split(' ')[0] : ''}
              </h1>
              <p>Start building connections!</p>
            </div>
          </div>

          <div className={styles.mainDiv}>
            <div className={styles.myCard}>
              <div className={styles.cardOptions}>
                <h3>My Card</h3>
                <div className={styles.cardLinks}>
                  <a onClick={() => toggleEditProfile(true)}>Edit Card</a>
                  <a href={`/user/${userID}`} target="_blank" rel="noreferrer">
                    Preview Card
                  </a>
                </div>
              </div>
              <BusinessCard data={userDetails} id={userID} qrRef={svgRef} />
            </div>
            <div className={styles.rightmainDiv}>
              <div className={styles.shareDiv}>
                <button
                  className={styles.shareButton}
                  onClick={() => {
                    if (navigator.share) {
                      const shareData = {
                        title:
                          'Hey Check Out My Digital Business Card at BondU \n',
                        text: 'Hey Check Out My Digital Business Card at BondU \n',
                        url: `https://www.app.bondu.in/user/${userID}`,
                      };
                      navigator
                        .share(shareData)
                        .then(() => {
                          console.log('Content shared successfully!');
                        })
                        .catch((error) => {
                          console.error('Error sharing content:', error);
                        });
                    } else {
                      console.log('Not supported');
                      //TODO: Make an alert here
                    }
                  }}
                >
                  Share
                </button>
                <div className={styles.shareDivButtons}>
                  <button onClick={downloadQR}>Download QR Code</button>
                  <button
                    onClick={() => {
                      var copyText = `https://www.app.bondu.in/user/${userID}`;
                      navigator.clipboard.writeText(copyText);

                      alert('Copied: ' + copyText);
                    }}
                  >
                    Copy Link
                  </button>
                </div>
              </div>
              <div className={styles.advertisement}>
                <img src={adPhoto} alt="" />
              </div>
            </div>
          </div>
          <canvas className={styles.canvas} id="canvas"></canvas>
          <div className={styles.contactsSections}>
            <div className={styles.contactTitle}>
              <h1>Recent Connections</h1>
              <a href="/dashboard">See all</a>
            </div>
            <div className={styles.contactsTable}>
              <div className={styles.contactHeading + ' ' + styles.contactRow}>
                <p>Name</p>
                <div>
                  <p className={styles.contactCompany}>Company</p>
                  <p className={styles.contactEmail}>Email</p>
                  <p>Phone no.</p>
                </div>
              </div>
              {contacts
                ? contacts.map((element) => {
                    return (
                      <div className={styles.contactRow}>
                        <p>{element.name}</p>
                        <div>
                          <p className={styles.contactCompany}>
                            {element.companyName}
                          </p>
                          <p className={styles.contactEmail}>{element.email}</p>
                          <p>{element.mobile}</p>
                        </div>
                      </div>
                    );
                  })
                : 'waiting'}
            </div>
          </div>
        </div>
      ) : (
        <CommingSoonPage toggleSidebar={toggleSidebar} />
      )}
    </div>
  ) : (
    <LoadingPage />
  );
}
