import { useState } from 'react';

export default function InputField({ title, userDetail, id }) {
  const [value, setValue] = useState(userDetail[id]);

  return (
    <div className="InputField">
      <label>{title}:</label>
      {title !== 'About' ? (
        <input
          type="text"
          placeholder={title}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            userDetail[id] = e.target.value;
          }}
        />
      ) : (
        <textarea
          type="text"
          rows="5"
          placeholder={title}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            userDetail[id] = e.target.value;
          }}
        />
      )}
    </div>
  );
}
