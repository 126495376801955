function SocialLink({ url, icon, title }) {
    return (
      <div className="handle">
        <a
          href={url}
          className="SocialLink"
          rel="noopener noreferrer"
          target="_blank"
          style={{ textDecoration: 'none' }}
        >
          <img src={icon} alt="" height="36px" width={"36px"}/>
          <h4>{title}</h4>
        </a>
      </div>
    );
  }
  
  export default SocialLink;
  