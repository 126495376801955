export const socialMediaHandlesDict = {
  Instagram: '	https://cdn-icons-png.flaticon.com/512/3955/3955024.png',
  Snapchat: 'https://cdn-icons-png.flaticon.com/512/15707/15707784.png',
  Pinterest: 'https://cdn-icons-png.flaticon.com/512/220/220214.png',
  Behance: 'https://cdn-icons-png.flaticon.com/512/145/145799.png',
  Whatsapp: 'https://cdn-icons-png.flaticon.com/512/3670/3670051.png',
  LinkedIn: 'https://cdn-icons-png.flaticon.com/512/145/145807.png',
  X: 'https://cdn-icons-png.flaticon.com/512/5969/5969020.png',
  Facebook: 'https://cdn-icons-png.flaticon.com/512/145/145802.png',
  Dribbble: 'https://cdn-icons-png.flaticon.com/512/3670/3670074.png',
  Web: 'https://cdn-icons-png.flaticon.com/512/10453/10453141.png',
  YouTube: 'https://cdn-icons-png.flaticon.com/512/3670/3670147.png',
  Maps: 'https://cdn-icons-png.flaticon.com/512/355/355980.png',
};
