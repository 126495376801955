import { useState } from 'react';
import TextField from './TextField';
import { addContact } from '../../services/apiHelper';

export default function SendContactPopup({ toggle, userID }) {
  const [contactData, setContactData] = useState({});
  const [loadingState, setLoadingState] = useState(false);

  async function handleSubmit() {
    if (
      contactData.name !== '' &&
      contactData.name != null &&
      ((contactData.mobile !== '' && contactData.mobile != null) ||
        (contactData.email !== '' && contactData.email != null))
    ) {
      setLoadingState(true);
      const responseStatus = await addContact(userID, contactData);
      if (responseStatus === 200) {
        alert('Contact Send Successfully');
      } else {
        alert('Some error occured');
      }
      setLoadingState(false);
      toggle();
    } else {
      alert('Fill your name and atleast one contact detail');
    }
  }

  return (
    <div className="SendContactPopup">
      <div className="PopUpHeader">
        <h1>Connect</h1>
        <button className="ClosePopUpButton" onClick={toggle}>
          X
        </button>
      </div>

      <div className="SendContactForm">
        <TextField title={'Name'} data={contactData} id={'name'} />
        <TextField title={'Mobile'} data={contactData} id={'mobile'} />
        <TextField title={'Email'} data={contactData} id={'email'} />
        <TextField title={'Company'} data={contactData} id={'companyName'} />

        <button className="SendContactButton" onClick={handleSubmit}>
          {loadingState ? 'Connecting' : 'Connect'}
        </button>
      </div>
    </div>
  );
}
