import { useEffect, useReducer, useState } from 'react';
import styles from './FTUEPage.module.css';
import { socialMediaHandlesDict } from '../../constants';

const reducerFunction = (state, action) => {
  if (action.type === 'ADD_MORE') {
    const handles = [...state];
    for (var i = 0; i < action.values.length; i++) {
      handles.push(action.values[i]);
    }

    return handles;
  }
};

export default function MoreSocialsPopUp({
  closeFunction,
  addHandle,
  handlesPresent,
}) {
  const [socialMediaHandles, dispatchSocialMediaHandles] = useReducer(
    reducerFunction,
    []
  );

  const getHandlestoAdd = () => {
    const listOfHandlesPresent = [];
    handlesPresent.map((elem, index) => {
      listOfHandlesPresent.push(elem.title);
      return 0;
    });

    const newHandles = [];
    for (var key in socialMediaHandlesDict) {
      if (
        socialMediaHandlesDict.hasOwnProperty(key) &&
        listOfHandlesPresent.findIndex((value) => value == key) < 0
      ) {
        newHandles.push({
          title: key,
          img: socialMediaHandlesDict[key],
        });
      }
    }
    dispatchSocialMediaHandles({ type: 'ADD_MORE', values: newHandles });
  };

  useEffect(() => {
    getHandlestoAdd();
  }, []);

  return (
    <div className={styles.MoreSocialsPopUp + ' ' + styles.popup}>
      <div className={styles.PopUpHeader}>
        <div>
          <h4>Connect your socials</h4>
          <p>Select an application from our huge library to connect</p>
        </div>
        <img
          src="https://cdn-icons-png.flaticon.com/512/2997/2997911.png"
          alt=""
          onClick={closeFunction}
        />
      </div>

      <div className={styles.socialsCatalogue}>
        {socialMediaHandles.map((handle, index) => {
          return (
            <div className={styles.socialGridItem} key={index}>
              <div>
                <img src={handle.img} alt="" />
                <h3>{handle.title}</h3>
              </div>

              <button
                className={styles.addSocialButton}
                onClick={() => addHandle(handle.title, handle.img)}
              >
                +
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
}
